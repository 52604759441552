<template>
  <div class="role-detail">
    <app-detail :opt="opt"></app-detail>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "菜单详情",
        form: [
          {
            label: "上级角色",
            key: "parent",
            disabled: true
          },
          {
            label: "角色名称",
            key: "name",
            rules: [{ required: true }]
          },
          {
            label: "角色类型",
            key: "type",
            type: "select",
            opt: {
              list: [
                {
                  label: "系统角色",
                  value: 1
                },
                {
                  label: "普通角色",
                  value: 2
                }
              ]
            },
            rules: [{ required: true }]
          },
          {
            label: "添加时间",
            key: "addTimeStr",
            only: ["show"]
          },
          {
            label: "角色描述",
            key: "des",
            type: "textarea"
          }
        ],
        get(opt) {
          let dto = {
            id: opt.id
          };
          _this.post("/role/detail", dto).then(data => {
            data.addTimeStr = _this.format(data.addTime);
            opt.cb(data);
          });
        },
        init(form){
          if (_this.$route.params.type == 'add') {
            let role = _this.cache.get('roleParent');
            form['parent'] = role.label;
          }
          return form;
        },
        buttons: [
          {
            name: "提交",
            show: ["add", "edit"],
            cb(form) {
              console.log(form);
              let dto = {
                name: form.name,
                sortValue: form.sortValue,
                isDisabled: form.isDisabled
              };
              let url = "/user/add";
              let message = "添加栏目成功";
              if (form.id) {
                url = "/user/update";
                dto.id = form.id;
                message = "编辑栏目成功";
              }
              _this.post(url, dto).then(function() {
                _this.$message({
                  showClose: true,
                  message: message,
                  type: "success"
                });
                _this.$back();
              });
            }
          }
        ]
      }
    };
  },
  created: function() {
    console.log("role-detail created!!");
  }
};
</script>

